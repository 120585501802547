export enum INSITEDataType {
    TILE3D     = 1, // Cesium 3D tile INSITEDataet
    KML        = 2, // KML or KMZ file
    OBJ3D      = 3, // 3D model e.g glTINSITEData
    FlightPath = 4, // 3D model e.g glTINSITEData
    ORTHO      = 5, // ortho image tileINSITEData
    DEM        = 6, // DEM terrain tiles
    POINTS     = 7, // pointcloud, e.gINSITEData LAZ
    PANO       = 8, // pano pin
    VIDEO      = 9, // mp4 videos
    VIDEOSET   = 10,
    PoleImage  = 12,
    CustomDataset =13,
    Photos = 14,
    // DOC // e.g. PDF report, etc
}

export const datasetTypeDropDown = [
    { id: 1, name: 'Cesium 3D Tiles'},      // DEM terrain tiles
    { id: 7, name: 'Cesium Point Cloud'},   // pointcloud, e.gINSITEData LAZ
    { id: 8, name: '360 Panorama Set'},     // pano pin
    { id: 2, name: 'KML' },                 // KML or KMZ file
    { id: 4, name: 'Video Flight Path' },   // KML or KMZ file
    { id: 9, name: 'Video' },           // mp4 Videos
    { id: 3, name: 'glTF 3D Object'},       // 3D model e.g glTINSITEData
    { id: 5, name: 'Ortho Map' },// ortho image tileINSITEData
    {id: 10, name: 'Video Set'},
    {id: 12, name:'PoleImage'},
    {id: 14, name:'Photos'},
    {id: 13, name:'Custom Dataset'},
    //{ id: 6, name: 'DEM Terrain' },         // Cesium 3D tile INSITEDataet
    // DOC // e.g. PDF report, etc
];


export const PinColors = [
    {color:'Yellow' ,  value:'Yellow'} ,
    {color:'Blue', value:'Blue'},
    {color:'White',  value:'White'},
    {color:'Green', value:'Green'},
    {color:'Red' , value:'Red'},
    {color:'Purple' , value:'Purple'}

];

/*

export const InsiteToolBar = [
    { id: 0, name: 'Cesium 3D Tiles'},      // DEM terrain tiles
    { id: 7, name: 'Cesium Point Cloud'},   // pointcloud, e.gINSITEData LAZ
    { id: 8, name: '360 Panorama Set'},     // pano pin
    { id: 2, name: 'KML' },                 // KML or KMZ file
    { id: 4, name: 'Video Flight Path' },   // KML or KMZ file
    { id: 9, name: 'Video' },           // mp4 Videos
    { id: 3, name: 'glTF 3D Object'},       // 3D model e.g glTINSITEData
    { id: 5, name: 'Ortho Map' },// ortho image tileINSITEData
    {id: 10, name: 'Video Set'},
    {id: 12, name:'PoleImage'},
    {id: 14, name:'Photos'},
    {id: 13, name:'Custom Dataset'},
    //{ id: 6, name: 'DEM Terrain' },         // Cesium 3D tile INSITEDataet
    // DOC // e.g. PDF report, etc
];
*/

export enum InsiteToolBar {
    Select,
    Point,
    Distance,
    Path,
    Area,
    Plane,
    Ray,
    Angle,
    Trash,
    ClipLayer,
    InfoLayer,
    Layers,
    PolyGone,
    Slope,
    Image ,
    POLE_PIN,
    CuboidVolume,
    CylinderVolume,
    GroupAnnotations,
    IrregularVolume,
    MilkTruck,
    WoodTower,
    CesiumMan,
    ConicSensor,
    ShippingContainer,
    Helicopter,
    CrossSection,
    Train,
    NGRTrain,
    ElectricPole,
    WindTurbine,
    Boat,
}


export const annotationsTypeDropDown = Object.keys(InsiteToolBar)
    .filter(key => isNaN(Number(key))) // filter out the reverse mappings
    .map(key => {
        return { id: InsiteToolBar[key as keyof typeof InsiteToolBar], name: key };
    });


export const alwaysVisibleTools = new Set([
    InsiteToolBar.Select,
    InsiteToolBar.InfoLayer,
    InsiteToolBar.Layers,
    InsiteToolBar.Trash,
    InsiteToolBar.Ray,
    InsiteToolBar.PolyGone,
    InsiteToolBar.Plane,
    InsiteToolBar.ClipLayer,
    InsiteToolBar.POLE_PIN,
    InsiteToolBar.Image,
    InsiteToolBar.Slope,
    // ... other always visible tool enum values
]);

export const customDatasetTypeDropDown = [
    { id: 1, name: 'Cesium 3D Tiles'},      // DEM terrain tiles
    { id: 7, name: 'Cesium Point Cloud'},   // pointcloud, e.gINSITEData LAZ
    { id: 8, name: '360 Panorama Set'},     // pano pin
    { id: 2, name: 'KML' },                 // KML or KMZ file
    { id: 4, name: 'Video Flight Path' },   // KML or KMZ file
    { id: 9, name: 'Video' },           // mp4 Videos
    { id: 3, name: 'glTF 3D Object'},       // 3D model e.g glTINSITEData
    { id: 5, name: 'Ortho Map' },// ortho image tileINSITEData
    {id: 10, name: 'Video Set'},
    {id: 12, name:'PoleImage'},
    {id: 13, name: 'Mixed Datasets'},
    //{ id: 6, name: 'DEM Terrain' },         // Cesium 3D tile INSITEDataet
    // DOC // e.g. PDF report, etc
];

export const PinColourDropdown = [
    { id: 1, name: 'Blue'},
    { id: 2, name: 'Yellow'},
    { id: 3, name: 'White'},
    { id: 4, name: 'Green' },
    { id: 5, name: 'Red' },
    { id: 6, name: 'Purple'},
];

export const Tiles3D = {
    name: {
        name: 'Tile Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    tile_path: {
        name: 'Tile Path',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    camera_path: {
        name: 'Camera Path ( .xml , .csv )',
        value: '',
        touched: false,
        valid: true,
        type: 'text',
        isUrl: true
    },
    height_offset: {
        name: 'Height',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    json_file_url: {
        name: 'JSON File',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    },
    rustDetection:{
        name: 'Rust Detection',
        value: false,
        checked: false,
        touched: false,
        valid: true,
        type: 'checkbox'
    }
};

export const CustomDataset = {
    datasetType: {
        name: 'Dataset Type',
        value: 'Cesium 3D Tiles',
        dstype: 1,
        touched: false,
        valid: false,
        type: 'select'
    },
    csv_path: {
        name: 'CSV Path',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
};


export const PoleImage = {
    name: {
        name: 'Cluster Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    pinColour: {
        name: 'Pin Colour',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    },
    imagePath: {
        name: 'Image Paths',
        value: [{url:""}],
        touched: false,
        valid: false,
        type: 'array'
    },
    csv_path: {
        name: 'CSV Path',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    clamp_to_terrain: {
        name: 'Clamp to Terrain',
        value: false,
        checked: false,
        touched: false,
        valid: true,
        type: 'checkbox'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }

};

export const Photos = {
    name: {
        name: 'Images Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    imagePath: {
        name: 'Image Paths',
        value: [{url:""}],
        touched: false,
        valid: false,
        type: 'array'
    },
    photoColour: {
        name: 'Photos Colour',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    },
    clamp_to_terrain: {
        name: 'Clamp to Terrain',
        value: false,
        checked: false,
        touched: false,
        valid: true,
        type: 'checkbox'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
}
export const KML = {
    name: {
        name: 'KML Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    kml_path: {
        name: 'KML Path',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    clamp_to_terrain: {
        name: 'Clamp to Terrain',
        value: true,
        checked: true,
        touched: false,
        valid: true,
        type: 'checkbox'
    },
    height_offset: {
        name: 'Height',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};

export const MODEL3D = {
    name: {
        name: 'Model Object Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    mesh_path: {
        name: 'Mesh Path',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    latitude: {
        name: 'Latitude',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    longitude: {
        name: 'Longitude',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    height: {
        name: 'Height',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    scale: {
        name: 'Scale',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    rotation: {
        name: 'Rotation',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }

};

export const FlightPath = {
    name: {
        name: 'Flight Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    flight_path: {
        name: 'Flight Path SRT',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    flight_path_gpx: {
        name: 'Flight Path GPX',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    video_path : {
        name: 'Video URL',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    video_subtitles_path: {
        name: 'Video Subtitles (.vtt)',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    json_file_url: {
        name: 'JSON URL',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    height_offset: {
        name: 'Height AGL',
        value: 20.0,
        touched: false,
        valid: false,
        type: 'number'
    },
    is360: {
        name: 'Is 360 Video',
        value: false,
        checked: false,
        touched: false,
        valid: false,
        type: 'checkbox'
    },
    flightPathColour: {
        name: 'Flight Colour',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};


export const VIDEO = {
    name: {
        name: 'Video Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
    },
    video_path : {
        name: 'Video URL',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    height_offset: {
        name: 'Height AGL',
        value: 20.0,
        touched: false,
        valid: false,
        type: 'number'
    },
    longitude: {
        name: 'Longitude',
        value: 0,
        touched: false,
        valid: false,
        type: 'number'
    },
    latitude: {
        name: 'Latitude',
        value: 0,
        touched: false,
        valid: false,
        type: 'number'
    },
    is360: {
        name: 'Is 360 Video',
        value: false,
        checked: false,
        touched: false,
        valid: false,
        type: 'checkbox'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};

export const VIDEOSET = {
    name: {
        name: 'Dataset Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    is360: {
        name: 'Select All',
        value: false,
        checked: false,
        touched: false,
        valid: true,
        type: 'checkbox'
    },
    videoArray: {
        name: 'Video Array',
        value: [{lat:0,lon:0,height:0,URL:"https://...",name:"",is360:false}],
        touched: false,
        valid: false,
        type: 'array'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};

export const POINTS = {
    name: {
        name: 'Dataset Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    pointcloud_path: {
        name: 'Point Cloud File URL',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    classified: {
        name: 'Classified',
        value: false,
        checked: false,
        touched: false,
        valid: true,
        type: 'checkbox'
    },
    classArray: {
        name: 'Class Array',
        value: [{id:null,name:"",colour:null}],
        touched: false,
        valid: false,
        hidden: true,
        type: 'array'
    },
    height_offset: {
        name: 'Height',
        value: '',
        touched: false,
        valid: false,
        type: 'number'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};


export const Ortho = {
    name: {
        name: 'Dataset Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    ortho_tiles_path: {
        name: 'Ortho tiles URL',
        value: '',
        touched: false,
        valid: false,
        type: 'text',
        isUrl: true
    },
    bounding_rect: {
        name: 'Bounding Rectangle (min_lon,min_lat,max_lon,max_lat || LL Long,LL Lat,UR Long,UR Lat)',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    }, 
    minimumLevel: {
        name: 'Minimum Zoom Level',
        value: '3',
        touched: false,
        valid: false,
        type: 'number'
    },
    maximumLevel: {
        name: 'Maximum Zoom Level',
        value: '22',
        touched: false,
        valid: false,
        type: 'number'
    },
    tileWidth: {
        name: 'Tile Width',
        value: '512',
        touched: false,
        valid: false,
        type: 'number'
    },
    tileHeight: {
        name: 'Tile height',
        value: '512',
        touched: false,
        valid: false,
        type: 'number'
    },
    layers: {
        name: 'WMS Layer(s) (comma separated)',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};

export const PANO = {
    name: {
        name: 'Dataset Name',
        value: '',
        touched: false,
        valid: false,
        type: 'text'
    },
    useVrPlayer: {
        name: 'Show in VR Player',
        value: true,
        checked: true,
        touched: false,
        valid: true,
        type: 'checkbox'
    },
    panoArray: {
        name: 'Pano Array',
        value: [{lat:0,lon:0,height:0,URL:"https://...",style:0,pinColour:'yellow'}],
        touched: false,
        valid: false,
        type: 'array'
    },
    subProject:{
        name: 'Sub Project',
        value: '',
        touched: false,
        valid: false,
        type: 'select'
    }
};
